<template>
  <div class="tg-star-awards-2024">
    <div class="tg-star-awards-2024_banner">
    </div>
    <div class="tg-star-awards-2024_category tg-star-awards-2024_container tg-star-awards-2024_flexc">
      <p v-for="(item, index) in categoryTitle" :key="item" @click="activeIndex = index">
        <img :src="activeIndex === index ? require(`./images/t${index+1}_ok.png`):require(`./images/t${index+1}.png`)" alt="">
      </p>
    </div>
     <!-- 奖项内容 -->
    <div
      v-for="(awards, index) in categoryContent"
      :key="index"
      class="tg-star-awards-2024_content tg-star-awards-2024_container"
      v-show="activeIndex === index"
    >
      <div class="awards-item" v-for="(award, aindex) in awards" :key="'award'+aindex">
        <div class="title">
          <h3>{{award.name}}</h3>
          <p>{{award.intro}}</p>
        </div>
        <div class="flex-between">
          <div class="game-item" v-for="(game, gindex) in award.games" :key="'game'+gindex">
            <div>
              <img :src="require(`./images/${categoryImg[index]}/${aindex+1}-${gindex+1}.jpg`)" onerror="this.src='./images/game/1-1.jpg'" alt="">
            </div>
            <div>{{ game }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  async asyncData({ route, store }) {
    await Promise.all([store.dispatch(`tgs/INIT_DATA`), store.dispatch(`tgs/FETCH`)]);
    // 返回 SSR 上下文，用于插入到 head 内
    return {};
  },
  data() {
    return {
      activeIndex: 0,
      categoryTitle: ['游戏类', '硬件类'],
      categoryImg: ['game', 'hardware'],
      categoryContent: [
        [
          {
            name: '年度游戏巨星奖',
            intro: '今年销量和口碑双双最佳的游戏作品',
            games: [
              '黑神话：悟空',
              '宇宙机器人',
              '小丑牌',
              '暗喻幻想：ReFantazio'
            ]
          },
          {
            name: '单机游戏杰出奖',
            intro: '在单机游戏中获得市场认可的佳作',
            games: [
              '寂静岭2',
              '剑星',
              '最终幻想7：重生',
              '碧蓝幻想：Relink'
            ]
          },
          {
            name: '移动游戏翘楚奖',
            intro: '在移动市场推出便获得成功的新手游',
            games: [
              '鸣潮',
              '宝可梦TCG口袋版',
              '剑与远征：启程',
              '女神异闻录：夜幕魅影'
            ]
          },
          {
            name: '独立游戏新星奖',
            intro: '在独立游戏中脱颖而出的亮眼之作',
            games: [
              '小丑牌',
              '动物井',
              'CATO黄油猫',
              'Neva'
            ]
          },
          {
            name: '国产游戏不凡奖',
            intro: '国内开发者推出的具有浓郁中国特色的作品',
            games: [
              '黑神话：悟空',
              '鸣潮',
              '绝区零',
              '地下城与勇士：起源'
            ]
          },
          {
            name: '卓越游戏发行奖',
            intro: '在海内外成功发行单款/多款游戏的游戏发行商',
            games: [
              '游戏科学',
              '腾讯游戏',
              '完美世界',
              '网易游戏'
            ]
          }
        ],
        [
          {
            name: '超人气游戏外设',
            intro: '今年上市获得成功的游戏周边外设',
            games: [
              '八位堂Retro108 机械键盘',
              '飞智八爪鱼4 手柄',
              '致态TiPlus7100 固态硬盘',
              '罗技G309 鼠标'
            ]
          },
          {
            name: '超人气游戏硬件',
            intro: '今年上市获得成功的游戏硬件',
            games: [
              'PS5 Pro',
              '微星x《怪物猎人》联名系列',
              'ROG玩家国度掌机X',
              'GPD WIN4 2024'
            ]
          },
          {
            name: '超人气游戏手机',
            intro: '今年上市获得成功的游戏手机',
            games: [
              '红魔 10 Pro',
              'ROG游戏手机9 Pro',
              'REDMI K80 Pro',
              '华为畅享70 Pro'
            ]
          },
          {
            name: '超人气数码产品',
            intro: '今年上市获得成功的游戏数码类产品',
            games: [
              '联想拯救者 Y700 三代',
              '红魔电竞平板 Pro',
              'Apple Vision Pro',
              '微星泰坦 GE78 HX 2024'
            ]
          },
          {
            name: '超人气显示设备',
            intro: '今年上市获得成功的显示器等显示外设',
            games: [
              '三星OLED G6',
              'AOC AGON AG246FK',
              '海信E8N Pro',
              'LG OLED evo C4'
            ]
          },
          {
            name: '超人气音频产品',
            intro: '今年上市获得成功的音频产品',
            games: [
              '苹果 AirPods 4',
              'AKG N9 HYBRID',
              '华为 FreeBuds Pro 4',
              '韶音 OpenRun Pro 2'
            ]
          }
        ]
      ]
    };
  }
};
</script>
